import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';
import { isElement, loop, preventDefault } from '../../helpers/_utilities';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const { get, getAll, hasClass, removeClass, addClass, toggleClass, exist, attr } = $dom;

export default function ankersFunctional(
  rootSelector = '.js-ankers-root'
) {
  // const
  //   buttonSelector = `${rootSelector}-button`,
  //   contentSelector = `${rootSelector}-content`
  //   ;

  const ankersRootSelector = '.js-ankers-root';
  const ankersBlockSelector = '.js-ankers-block';
  const sidemenuRootSelector = '.js-sidemenu';
  const sidemenuItemSelector = '.js-sidemenu-item';

  if (exist([ankersRootSelector, ankersBlockSelector, sidemenuRootSelector, sidemenuItemSelector])) {

    let opened = [];

    const { active, disabled } = variables.classNames;

    // const collapseHandler = event => {
    //   const target = event.target.closest(collapseRootSelector);

    //   console.log(target);

    //   toggleClass(target, active);
    //   toggleClass(get(collapseButtonSelector, target), active);
    //   toggleClass(get(collapseContentSelector, target), active);
    // }

    const clearClasses = () => {
      getAll(sidemenuItemSelector).forEach(element => {
        removeClass(element, 'is-current');
      })
    }

    let panels = gsap.utils.toArray(ankersBlockSelector);
    // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
    // let tops = panels.map(panel => ScrollTrigger.create({ trigger: panel, start: "top top" }));

    panels.forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        // start: () => panel.offsetHeight < window.innerHeight ? "-2% top" : "bottom 50%", // if it's shorter than the viewport, we prefer to pin it at the top
        // pin: true,
        start: "-10px 80px",
        // end: 'bottom bottom',
        // markers: true,
        // pinSpacing: false

        onEnter: () => {

          const blockId = attr(panel, 'id');

          const currentLink = get(`${sidemenuItemSelector}[href="#${blockId}"]`);

          clearClasses();

          addClass(currentLink, 'is-current');
        },

        onEnterBack: () => {

          const blockId = attr(panel, 'id');

          const currentLink = get(`${sidemenuItemSelector}[href="#${blockId}"]`);

          clearClasses();

          addClass(currentLink, 'is-current');
        },
      });
    });

    ScrollTrigger.create({
      trigger: sidemenuRootSelector,
      // start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
      start: 'top top',
      end: 'bottom bottom',
      pin: true,
      // markers: true,
      pinSpacing: false
    });

    const init = () => {
      $events.delegate
        .on('click tap', sidemenuRootSelector, event => {
          event.preventDefault();

          const link = event.target.closest(sidemenuItemSelector);

          // console.log(link);

          const block = get(attr(link, 'href'), ankersRootSelector);

          // console.log(block.offsetTop);

          const yOffset = -50;
          const y = block.getBoundingClientRect().top + window.scrollY + yOffset;

          window.scrollTo({
            top: y,
            behavior: 'smooth'
          });
        })
      // .on('click tap', '.js-scrollup', () => {
      //   console.log('13232131');
      //   window.scrollTo({ top: 0, behavior: 'smooth' });
      // });
    };

    const destroy = () => {
      // $events.delegate
      //   .off('click tap', collapseRootSelector, collapseHandler);
    }
      ;


    return { init, destroy }
  }

  return false;
}